import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SecurityIcon from '@material-ui/icons/Security';
import Alert from '@material-ui/lab/Alert';
import { Formik, FormikBag } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as yup from 'yup';

import { UnauthorizedError } from 'core/util/errors';
import { AppState } from 'store';
import { submit2faThunk } from 'store/system/system.actions';

const authTypeDescription = {
  AUTHENTICATOR: 'Enter a code from your authenticator app',
  EMAIL: 'An email was sent to you with a verification code, please fill in the received code',
  SMS: 'A text message was sent to you with a verification code, please fill in the received code',
};

const formSchema = yup.object({
  code: yup.string().required(),
});

type SchemaValues = yup.InferType<typeof formSchema>;

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const TwoFactor = props => {
  const dispatch = useDispatch();
  const systemError = useSelector((store: AppState) => store.system.error);
  const location = useLocation();
  const classes = useStyles(props);
  const authType = new URLSearchParams(location.search).get('type') || '';

  const onSubmit = (values: SchemaValues, bag: FormikBag<{}, SchemaValues>) => {
    try {
      bag.setSubmitting(true);
      const search = new URLSearchParams(location.search);
      const service = search.get('service');
      const type = search.get('type');
      const secret = search.get('secret');
      if (service && type && secret) {
        dispatch(
          submit2faThunk({
            service,
            type,
            secret,
            code: values.code,
          }),
        );
      } else {
        throw new Error('Incorrect 2FA params');
      }
    } catch (err) {
      bag.setStatus('ERROR');
    } finally {
      bag.setSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <SecurityIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik<SchemaValues>
          validationSchema={formSchema}
          onSubmit={onSubmit}
          initialValues={{
            code: '',
          }}
        >
          {({ handleChange, handleSubmit, values, errors, submitCount }) => {
            const hasSubmitted = submitCount > 0;
            return (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Alert severity="info">{authTypeDescription[authType]}</Alert>
                {systemError && [UnauthorizedError.NAME].includes(systemError.name) && (
                  <Alert severity="error">Invalid verification code</Alert>
                )}
                <TextField
                  value={values.code}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Verification Code"
                  name="code"
                  autoFocus
                  onChange={handleChange}
                  error={hasSubmitted && Boolean(errors.code)}
                  helperText={errors.code}
                />
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  Submit
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/login" variant="body2">
                      Cancel
                    </Link>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};
