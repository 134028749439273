/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';

const isUsInfra = () => {
  if (window.location.hostname.includes('-us')) return true;
};

const ACCOUNTS_URL = __DEV__ ? ACCOUNTS_DEV_URL : isUsInfra() ? ACCOUNTS_SERVER_URL_US : ACCOUNTS_SERVER_URL;
const HCP_URL = __DEV__ ? HCP_DEV_URL : isUsInfra() ? HCP_SERVER_URL_US: HCP_SERVER_URL;
const RESMED_URL = __DEV__ ? RESMED_DEV_URL : RESMED_SERVER_URL;

export const accountsApi = axios.create({
  baseURL: ACCOUNTS_URL,
});

export const hcpApi = axios.create({
  baseURL: HCP_URL,
});

function setTokenOnInstance(instance: AxiosInstance, token: string, service: string) {
  instance.defaults.headers['x-auth-token'] = token;
  instance.defaults.headers['x-signing-service'] = service;
}

function resetTokenOnInstance(instance: AxiosInstance) {
  delete instance.defaults.headers['x-auth-token'];
  delete instance.defaults.headers['x-signing-service'];
}

export function setAuthToken(token: string | null, service = 'HCP'): void {
  if (token === null) {
    resetTokenOnInstance(accountsApi);
    resetTokenOnInstance(hcpApi);
  } else {
    setTokenOnInstance(accountsApi, token, service);
    setTokenOnInstance(hcpApi, token, service);
  }
}

export function getAccountsUrl(url: string): string {
  return `${ACCOUNTS_URL}/${url}`;
}

export function getHcpUrl(url: string): string {
  return `${HCP_URL}/${url}`;
}

export function getResmedUrl(url: string): string {
  return `${RESMED_URL}/${url}`;
}
