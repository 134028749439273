import { hcpApi } from './axios';

export const fetchPDFReportsList = async (query, sort, page, page_size) => {
  const reportsResponse = await hcpApi.get('reports/pdf', {
    params: {
      query,
      page,
      page_size,
      sort,
    },
  });
  return reportsResponse.data.reports;
};

export const fetchPDFReport = async id => {
  const reportResponse = await hcpApi.get(`reports/pdf/${id}`);
  return reportResponse.data.report;
};

export const patchPDFReport = async (id, body) => {
  const reportResponse = await hcpApi.patch(`reports/pdf/${id}`, body);
  return reportResponse.data.report;
};

export const fetchSummaryReportsList = async (query, sort, page, page_size) => {
  const reportsResponse = await hcpApi.get('reports/summary', {
    params: {
      query,
      page,
      page_size,
      sort,
    },
  });
  return reportsResponse.data.reports;
};

export const fetchSummaryReport = async id => {
  const reportResponse = await hcpApi.get(`reports/summary/${id}`);
  return reportResponse.data.report;
};

export const patchSummaryReport = async (id, body) => {
  const reportResponse = await hcpApi.patch(`reports/summary/${id}`, body);
  return reportResponse.data.report;
};

export const reviewSummaryReport = async (id, body) => {
  const reportResponse = await hcpApi.patch(`reports/summary/${id}/approve`, body);
  return reportResponse.data.report;
};

export const getFile = async file => {
  return hcpApi.get(`files/url/${file}`).then(result => result.data.file_url);
};

export const getReportForRecording = (recording: id) => {
  return hcpApi.get(`reports/recording/${recording}`).then(res => {
    if (res.data.report) {
      return res.data.report;
    }
    return null;
  });
};

export const getReportForTest = (test: id) => {
  return hcpApi.get(`reports/test/${test}`).then(res => {
    if (res.data.report) {
      return res.data.report;
    }
    return null;
  });
};
