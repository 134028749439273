import * as account from './account.api';
import * as auth from './auth.api';
import * as centers from './centers.api';
import * as enrollments from './enrollments.api';
import * as file from './file.api';
import * as fulfillments from './fulfillments.api';
import * as recordings from './recordings.api';
import * as reports from './reports.api';
import * as tests from './tests.api';
import * as users from './users.api';

export const api = {
  auth,
  account,
  centers,
  enrollments,
  fulfillments,
  tests,
  reports,
  recordings,
  file,
  users,
};
