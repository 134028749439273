import { User } from 'store/system/system.types';

export const parseUser = (user: UserAPI): User => {
  return {
    id: user._id,
    firstname: user.first_name,
    lastname: user.last_name,
    phone: user.phone_number,
    email: user.email,
    roles: user.roles,
    languagePreference: user.language_preference,
    passwordUpdatedAt: user.password_updated_at,
    createdAt: user.created_at,
    modifiedAt: user.modified_at,
  };
};

export const parse2fa = (twofa: TwoFaApi) => {
  return {
    id: twofa._id,
    user: twofa.user,
    type: twofa.type,
    value: twofa.value,
  };
};
