import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Login } from 'authentication/Login';
import { TwoFactor } from 'authentication/TwoFactor';
import { AppState } from 'store';

const Application = lazy(() => import('application'));

// https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=58c6b4&secondary.color=566463&primary.text.color=000000&secondary.text.color=ffffff
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#8df9e6',
      main: '#58c6b4',
      dark: '#189584',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#839290',
      main: '#566463',
      dark: '#2d3a39',
      contrastText: '#ffffff',
    },
  },
});

interface InjectedProps {
  initialized: boolean;
  authenticated: boolean;
}

type Props = InjectedProps & RouteProps;

function App() {
  const initialized = useSelector((store: AppState) => store.system.initialized);
  const authenticated = useSelector((store: AppState) => store.system.authenticated);
  if (initialized) {
    const loggedIn = authenticated;
    return (
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div>Loading</div>}>
          <Helmet>
            <title>Ectosense Admin</title>
          </Helmet>
          <Switch>
            <Route path="/login/2fa" render={() => (loggedIn ? <Redirect to="/" /> : <TwoFactor />)} />
            <Route path="/login" render={() => (loggedIn ? <Redirect to="/" /> : <Login />)} />
            <Route path="/forgot_password" render={() => (loggedIn ? <Redirect to="/" /> : <Login />)} />
            <Route path="/reset/password" render={() => <Login />} />
            <Route path="/" render={() => (loggedIn ? <Application /> : <Redirect to="/login" />)} />
          </Switch>
        </Suspense>
      </ThemeProvider>
    );
  }
  return <div>Loading</div>;
}

export default hot(module)(App);
