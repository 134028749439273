import {
  EDIT_ACCOUNT_RESPONSE,
  FETCH_2FA_RESPONSE,
  INIT_SYSTEM,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT_RESPONSE,
  SUBMIT_2FA_RESPONSE,
  SystemActionTypes,
  SystemState,
} from './system.types';

const initialState = {
  initialized: false,
  authenticated: false,
  expired: false,
  name: '',
  fetching: false,
  error: null,
  user: undefined,
  twofa: null,
  redirect2FA: false,
};

export function systemReducer(state: SystemState = initialState, action: SystemActionTypes) {
  switch (action.type) {
    case INIT_SYSTEM:
      return {
        ...state,
        initialized: true,
        authenticated: action.payload.authenticated,
        expired: Boolean(action.payload.expired),
        user: action.payload.user,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case LOGIN_RESPONSE:
    case SUBMIT_2FA_RESPONSE:
      return {
        ...state,
        error: action.payload.error || null,
        authenticated: !action.payload.error,
        fetching: false,
        user: action.payload.user,
        redirect2FA: action.payload.redirect2FA,
      };
    case LOGOUT_RESPONSE:
      return {
        ...state,
        initialized: true,
      };
    case FETCH_2FA_RESPONSE:
      return {
        ...state,
        error: action.payload.error || null,
        twofa: action.payload.twofa,
      };
    case EDIT_ACCOUNT_RESPONSE:
      return {
        ...state,
        error: action.payload.error || null,
        user: action.payload.user,
      };
    default:
      return state;
  }
}
