import { hcpApi } from './axios';

export const fetchRecordings = async (params, page: number, page_size: number): Promise<Recording[]> => {
  const recordingsResponse = await hcpApi.get('recordings/query', {
    params: {
      ...params,
      page,
      page_size,
    },
  });
  return recordingsResponse.data.recordings;
};

export const fetchRecording = async (id: id): Promise<Recording> => {
  const recordingResponse = await hcpApi.get(`recordings/${id}`);
  return recordingResponse.data.recording;
};
