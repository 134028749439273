import { AxiosError } from 'axios';

export interface SystemState {
  initialized: boolean;
  authenticated: boolean;
  expired: boolean;
  name: string;
  fetching: boolean;
  error: null | Error | AxiosError;
  user?: User;
  twofa: TwoFa | null;
  redirect2FA: boolean;
}

export interface User {
  id: id;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  roles: string[];
  passwordUpdatedAt: string;
  languagePreference: string;
  createdAt: string;
  modifiedAt: string;
}

export interface TwoFa {
  id: id;
  user: id;
  type: string;
  value: string;
}

export interface Physician {
  id: string;
  firstname: string;
  lastname: string;
  language: string;
  email: string;
  phone: string;
  masterPhysician: boolean;
}

export const INIT_SYSTEM = 'INIT_SYSTEM';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGOUT_RESPONSE = 'LOGOUT_RESPONSE';
export const FETCH_2FA_REQUEST = 'FETCH_2FA_REQUEST';
export const FETCH_2FA_RESPONSE = 'FETCH_2FA_RESPONSE';
export const SUBMIT_2FA_REQUEST = 'SUBMIT_2FA_REQUEST';
export const SUBMIT_2FA_RESPONSE = 'SUBMIT_2FA_RESPONSE';
export const EDIT_ACCOUNT_REQUEST = 'EDIT_ACCOUNT_REQUEST';
export const EDIT_ACCOUNT_RESPONSE = 'EDIT_ACCOUNT_RESPONSE';

interface InitSystemAction {
  type: typeof INIT_SYSTEM;
  payload: {
    authenticated: boolean;
    expired: boolean;
    user?: User;
  };
}

interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

interface LoginResponseAction {
  type: typeof LOGIN_RESPONSE;
  payload: {
    error?: any;
    user?: User;
    redirect2FA?: boolean;
  };
}

interface LogoutResponseAction {
  type: typeof LOGOUT_RESPONSE;
}

interface Fetch2faRequestAction {
  type: typeof FETCH_2FA_REQUEST;
}

interface Fetch2faResponseAction {
  type: typeof FETCH_2FA_RESPONSE;
  payload: {
    error?: any;
    twofa?: TwoFa | null;
  };
}

interface Submit2faRequestAction {
  type: typeof SUBMIT_2FA_REQUEST;
}

interface Submit2faResponseAction {
  type: typeof SUBMIT_2FA_RESPONSE;
  payload: {
    error?: any;
    user?: User;
    redirect2FA: false;
  };
}

interface EditAccountRequestAction {
  type: typeof EDIT_ACCOUNT_REQUEST;
}

interface EditAccountResponseAction {
  type: typeof EDIT_ACCOUNT_RESPONSE;
  payload: {
    error?: any;
    user?: User;
  };
}

export type SystemActionTypes =
  | InitSystemAction
  | LoginRequestAction
  | LoginResponseAction
  | Fetch2faRequestAction
  | Fetch2faResponseAction
  | Submit2faRequestAction
  | Submit2faResponseAction
  | LogoutResponseAction
  | EditAccountRequestAction
  | EditAccountResponseAction;
