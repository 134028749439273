import { PasswordMismatchError, UnauthorizedError } from 'core/util/errors';
import { TwoFa, User } from 'store/system/system.types';

import { setAuthToken, accountsApi } from './axios';
import { parse2fa, parseUser } from './transformers/users.transformer';

export function getUser(): Promise<User> {
  return accountsApi.get(`users/me`).then(response => {
    return parseUser(response.data.user);
  });
}

export function editUser(user: id, firstname: string, lastname: string, email: string, locale: string): Promise<User> {
  return accountsApi
    .patch(`users/${user}`, {
      first_name: firstname,
      last_name: lastname,
      email,
      language_preference: locale,
    })
    .then(result => parseUser(result.data.user));
}

export function editPassword(user: id, oldPassword: string, newPassword: string, passwordConfirmation: string): Promise<void> {
  if (newPassword === passwordConfirmation) {
    return accountsApi
      .patch(`users/password/${user}`, {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: passwordConfirmation,
      })
      .then(result => {
        // TODO extract service
        setAuthToken(result.data['x-auth-token']);
        return result.data;
      })
      .catch(error => {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
        throw error;
      });
  }
  throw new PasswordMismatchError();
}

export function get2FA(): Promise<TwoFa | null> {
  return accountsApi
    .get<getTwoFaResponse>('2fa')
    .then(response => {
      return parse2fa(response.data.two_fa);
    })
    .catch(e => {
      if (e.response?.status === 404) {
        return null;
      }
      throw e;
    });
}

export function enable2FA(body: { type: string; password: string; typeValue: string }): Promise<TwoFaEnableResponse> {
  return accountsApi.post<TwoFaEnableResponse>('2fa/enable', body).then(response => {
    return response.data;
  });
}

export function disable2FA(password: string): Promise<string> {
  return accountsApi
    .post<{ secret: string }>('2fa/disable', { password })
    .then(response => {
      return response.data.secret;
    });
}
