import { omit } from 'lodash';

export function prepareCenterPatch(center) {
  return omit(center, ['_id', 'created_at', 'modified_at', 'logo']);
}

export function prepareCreateCenterSettings(defaultSettings, inputSettings) {
  const keys = Object.keys(defaultSettings);

  for (const key of keys) {
    if (inputSettings[key] === undefined || inputSettings[key] === null || inputSettings[key] === '') {
      inputSettings[key] = defaultSettings[key];
    }
  }
  return inputSettings;
}
