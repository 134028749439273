import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import { Formik, FormikBag } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import { ApiLoginResponse } from 'api/auth.api';
import { UnauthorizedError } from 'core/util/errors';
import { AppState } from 'store';
import { loginThunk } from 'store/system/system.actions';

const formSchema = yup.object({
  service: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required(),
});

type SchemaValues = yup.InferType<typeof formSchema>;

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Login = props => {
  const dispatch = useDispatch();
  const systemError = useSelector((store: AppState) => store.system.error);
  const history = useHistory();
  const classes = useStyles(props);

  const onSubmit = async (values: SchemaValues, bag: FormikBag<{}, SchemaValues>) => {
    try {
      bag.setSubmitting(true);
      const response = ((await dispatch(loginThunk(values))) as unknown) as ApiLoginResponse;
      if (response.type === '2FA') {
        history.push({
          pathname: '/login/2fa',
          search: `?service=${values.service}&type=${response.authType}&secret=${response.secret}`,
        });
      }
    } catch (err) {
      bag.setStatus('ERROR');
    } finally {
      bag.setSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik<SchemaValues>
          validationSchema={formSchema}
          onSubmit={onSubmit}
          initialValues={{
            email: '',
            service: localStorage.getItem('lastService') || '',
            password: '',
          }}
        >
          {({ handleChange, handleSubmit, values, errors, submitCount }) => {
            const hasSubmitted = submitCount > 0;
            return (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                {systemError && [UnauthorizedError.NAME].includes(systemError.name) && <Alert severity="error">Invalid credentials</Alert>}
                {systemError?.response?.status === 404 && <Alert severity="error">User with given email address does not exist</Alert>}
                <TextField
                  value={values.service}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="service"
                  label="Service"
                  name="service"
                  autoFocus
                  onChange={handleChange}
                  error={hasSubmitted && Boolean(errors.service)}
                  helperText={errors.service}
                />
                <TextField
                  value={values.email}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                  error={hasSubmitted && Boolean(errors.email)}
                  helperText={errors.email}
                />
                <TextField
                  value={values.password}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  error={hasSubmitted && Boolean(errors.password)}
                  helperText={errors.password}
                />
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/forgot_password" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};
