import { AsyncAction, AsyncFulfilledAction } from 'types/redux-thunk-promise';

export interface RecordingsState {
  byId: {
    [id: string]: Recording;
  };
  allIds: string[];
}

export const RECORDINGS_LIST_FETCH = 'RECORDINGS_LIST/FETCH';
export const RECORDINGS_LIST_FETCH_PENDING = 'RECORDINGS_LIST/FETCH_PENDING';
export const RECORDINGS_LIST_FETCH_FULFILLED = 'RECORDINGS_LIST/FETCH_FULFILLED';
export const RECORDINGS_LIST_FETCH_REJECTED = 'RECORDINGS_LIST/FETCH_REJECTED';

interface RecordingsListFetchAction extends AsyncAction<RecordingsListApi> {
  type: typeof RECORDINGS_LIST_FETCH;
}

type RecordingsListFetchFulfilledAction = AsyncFulfilledAction<RecordingsListFetchAction, typeof RECORDINGS_LIST_FETCH_FULFILLED>;

export const RECORDING_FETCH = 'RECORDING/FETCH';
export const RECORDING_FETCH_PENDING = 'RECORDING/FETCH_PENDING';
export const RECORDING_FETCH_FULFILLED = 'RECORDING/FETCH_FULFILLED';
export const RECORDING_FETCH_REJECTED = 'RECORDING/FETCH_REJECTED';

interface RecordingFetchAction extends AsyncAction<Recording> {
  type: typeof RECORDING_FETCH;
}

type RecordingFetchFulfilledAction = AsyncFulfilledAction<RecordingFetchAction, typeof RECORDING_FETCH_FULFILLED>;

export type RecordingsActionTypes =
  | RecordingsListFetchAction
  | RecordingsListFetchFulfilledAction
  | RecordingFetchAction
  | RecordingFetchFulfilledAction;
