import 'react-hot-loader';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import { rootReducer } from 'store';
import * as mw from 'store/middlewares';

import './index.css';
import { initSystemThunk } from 'store/system/system.actions';

import App from './App';

const middlewares = [thunk, promise, mw.crashReporter];

if (__DEV__) {
  // const { createLogger } = require(`redux-logger`);
  // const logger = createLogger();
  // middlewares.push(logger);
} else {
  Sentry.init({
    dsn: 'https://0c0485a15f3642448533be2abfddd1ec@o184993.ingest.sentry.io/1882677',
    release: RELEASE,
    environment: HOST_ENV,
  });
  Sentry.configureScope(scope => {
    scope.setTag('version', VERSION);
  });
}

function initStore() {
  const composeEnhancers = (__DEV__ && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));
  store.dispatch(initSystemThunk());

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./store', () => store.replaceReducer(rootReducer));
  }

  return store;
}

function renderApp(store) {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp(initStore());
