import { hcpApi } from './axios';

export const fetchTestsList = async (params, page, page_size) => {
  const response = await hcpApi.get('tests', {
    params: {
      ...params,
      page,
      page_size,
    },
  });
  return response.data.tests;
};

export const fetchTest = async (id, params) => {
  const response = await hcpApi.get(`tests/${id}`, { params });
  return response.data.test;
};

export function fetchActivationCode(test) {
  return hcpApi
    .get('activation/find', {
      params: {
        query: JSON.stringify({
          test,
        }),
      },
    })
    .then(res => {
      if (res.data.AC.length === 0) {
        throw new Error(`No activation code found for test ${test.id}`);
      }
      return res.data.AC[0];
    });
}

export function resendActivationCode({test, language, email}) {
  return hcpApi
    .post(`activation/resend/${test}`, {
      language,
      email
    });
}
