import { UnauthorizedError } from 'core/util/errors';
import { User } from 'store/system/system.types';

import { getUser } from './account.api';
import { setAuthToken, accountsApi } from './axios';

export async function init() {
  // User is stored in localStorage as { user: userID, session: sessionToken, service: ServiceName}
  const storedSession = localStorage.getItem('adminSession');
  if (storedSession) {
    const { token, service } = JSON.parse(storedSession);
    setAuthToken(token, service);
    return validate();
  }
  throw new Error('No session token stored');
}

export function validate(): Promise<User> {
  return getUser().catch(e => {
    setAuthToken(null);
    throw e;
  });
}

export type ApiLoginResponse = { type: 'LOGIN'; user: User; redirect2FA: boolean } | { type: '2FA'; authType: string; secret: string };

export async function login(service: string, email: string, password: string): Promise<ApiLoginResponse> {
  try {
    const loginResponse = await accountsApi.post<LoginResponse>(service === 'ACCOUNTS' ? 'users/login/admin' : 'users/login/service', {
      service,
      email,
      password,
    });
    if (loginResponse.data.message === 'Awaiting 2FA') {
      return {
        type: '2FA',
        authType: loginResponse.data.type,
        secret: loginResponse.data.secret,
      };
    }

    if (loginResponse.data.message === 'Login Successful') {
      const token = loginResponse.data['x-auth-token'];
      setAuthToken(token, service);
      localStorage.setItem('adminSession', JSON.stringify({ email, token, service, saved_at: new Date().toISOString() }));
      const userResponse = await validate();
      return {
        type: 'LOGIN',
        user: userResponse,
        redirect2FA: loginResponse.data.redirect2FA,
      };
    }

    throw new Error('Unsupported login response');
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new UnauthorizedError();
    }
    throw error;
  }
}

export async function submit2fa(secret: string, code: string, type: string, service: string): Promise<{ type: 'LOGIN'; user: User }> {
  let token: string;
  try {
    const twoFa = await verify2FA({ secret, code, type }, service);
    token = twoFa['x-auth-token'];
    setAuthToken(token, service);
    const userResponse = await validate();
    localStorage.setItem('adminSession', JSON.stringify({ email: userResponse.email, token, service, saved_at: new Date().toISOString() }));
    return {
      type: 'LOGIN',
      user: userResponse,
    };
  } catch (error) {
    if (error.response.status === 401) {
      throw new UnauthorizedError();
    }
    throw error;
  }
}

export function verify2FA(body: { secret: string; code: string; type: string }, service?: string): Promise<LoginResponseSuccess> {
  return accountsApi
    .post<LoginResponseSuccess>('2fa/verify', body, {
      headers: {
        'x-signing-service': service,
      },
    })
    .then(response => {
      return response.data;
    });
}

export function forgotPassword(email: string) {
  return accountsApi.post('physicians/password/forgot', { email }).then(result => result.data);
}

// tslint:disable-next-line: variable-name
export function resetPassword(token: string, new_password: string, confirm_password: string) {
  return accountsApi.post('users/password/reset', { new_password, confirm_password, token }).then(response => {
    return response.data;
  });
}

export async function logout(): Promise<void> {
  setAuthToken(null);
  localStorage.removeItem('adminSession');
}
