import { prepareCenterPatch } from 'api/transformers/centers.transformer';
import _ from 'lodash';

import { hcpApi } from './axios';

export const fetchCentersList = async (params, page, page_size) => {
  const response = await hcpApi.get('units', {
    params: {
      ...params,
      page,
      page_size,
      with_related: true,
    },
  });
  return response.data.units;
};

export const fetchCenter = async id => {
  const response = await hcpApi.get(`units/${id}`, {
    params: {
      with_related: true,
    },
  });
  return response.data.unit;
};

export const fetchCenterStatistics = async id => {
  const response = await hcpApi.get(`units/statistics/${id}`);
  return response.data;
};

export const createCenter = async body => {
  const response = await hcpApi.post(`units`, body);
  return response.data.unit;
};

export const patchCenter = async (id, body) => {
  await hcpApi.patch(`units/${id}`, _.omit(prepareCenterPatch(body), 'settings', 'surveys', 'address'));
  await hcpApi.patch(`units/settings/${id}`, body.settings);
  if (body?.address?.address !== undefined) {
    await hcpApi.patch(`units/address/${id}`, body.address);
  }
  return body;
};

export const patchCenterSettings = async (id, settings) => {
  await hcpApi.patch(`units/settings/${id}`, settings);
};
