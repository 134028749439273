import { uniq, cloneDeep } from 'lodash';

import {
  RECORDING_FETCH_FULFILLED,
  RECORDINGS_LIST_FETCH_FULFILLED,
  RecordingsActionTypes,
  RecordingsState,
} from 'store/recordings/recordings.types';

const initialState = {
  byId: {},
  allIds: [],
};

export function recordingsReducer(state: RecordingsState = initialState, action: RecordingsActionTypes) {
  switch (action.type) {
    case RECORDING_FETCH_FULFILLED: {
      return {
        byId: {
          ...state.byId,
          [action.payload._id]: cloneDeep(action.payload),
        },
        allIds: uniq([...state.allIds, action.payload._id]),
      };
    }
    case RECORDINGS_LIST_FETCH_FULFILLED: {
      const newRecordings = action.payload.reduce((acc, value) => {
        acc[value._id] = cloneDeep(value);
        return acc;
      }, {});
      return {
        byId: {
          ...state.byId,
          ...newRecordings,
        },
        allIds: uniq([...state.allIds, ...Object.keys(newRecordings)]),
      };
    }
    default:
      return state;
  }
}
