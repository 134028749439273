import { combineReducers } from 'redux';

import { centersReducer } from 'store/centers/centers.reducer';
import { CentersState } from 'store/centers/centers.types';
import { recordingsReducer } from 'store/recordings/recordings.reducer';
import { RecordingsState } from 'store/recordings/recordings.types';

import { systemReducer } from './system/system.reducer';
import { SystemState, LOGOUT_RESPONSE } from './system/system.types';

export interface AppState {
  system: SystemState;
  entities: {
    recordings: RecordingsState;
    centers: CentersState;
  };
  // domains: {};
}

// const domainReducer = combineReducers({});

const entityReducer = combineReducers({
  recordings: recordingsReducer,
  centers: centersReducer,
});

// const uiReducer = combineReducers({});

export const appReducer = combineReducers({
  system: systemReducer,
  entities: entityReducer,
  // ui: uiReducer,
  // domains: domainReducer,
});

export const rootReducer = (state, action) => {
  let newState = { ...state };
  if (action.type === LOGOUT_RESPONSE) {
    newState = undefined;
  }

  return appReducer(newState, action);
};
