import { ThunkAction } from 'redux-thunk';

import { api } from 'api';
import { ApiLoginResponse } from 'api/auth.api';
import { AppState } from 'store';

import {
  EDIT_ACCOUNT_REQUEST,
  EDIT_ACCOUNT_RESPONSE,
  FETCH_2FA_REQUEST,
  FETCH_2FA_RESPONSE,
  INIT_SYSTEM,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT_RESPONSE,
  SUBMIT_2FA_REQUEST,
  SUBMIT_2FA_RESPONSE,
  SystemActionTypes,
  TwoFa,
  User,
} from './system.types';

export type SystemThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, null, SystemActionTypes>;

export const initSystemThunk = (): SystemThunk => async dispatch => {
  try {
    const user = await api.auth.init();
    dispatch(initSystem({ authenticated: true, user }));
  } catch (e) {
    dispatch(initSystem({ authenticated: false }));
  }
};

const initSystem = (payload): SystemActionTypes => {
  return {
    type: INIT_SYSTEM,
    payload,
  };
};

export const loginThunk = (payload: {
  email: string;
  password: string;
  service: string;
}): SystemThunk<Promise<ApiLoginResponse>> => async dispatch => {
  dispatch(loginRequest());
  try {
    const response = await api.auth.login(payload.service, payload.email, payload.password);
    if (response.type === 'LOGIN') {
      dispatch(loginResponse(null, response.user, response.redirect2FA));
      localStorage.setItem('lastService', payload.service);
    }
    return response;
  } catch (e) {
    dispatch(loginResponse(e));
    throw e;
  }
};

const loginRequest = (): SystemActionTypes => {
  return {
    type: LOGIN_REQUEST,
  };
};

const loginResponse = (error, user?: User, redirect2FA?: boolean): SystemActionTypes => {
  return {
    type: LOGIN_RESPONSE,
    payload: {
      error,
      user,
      redirect2FA,
    },
  };
};

const fetch2faRequest = (): SystemActionTypes => {
  return {
    type: FETCH_2FA_REQUEST,
  };
};

const fetch2faResponse = (error, twofa?: TwoFa | null): SystemActionTypes => {
  return {
    type: FETCH_2FA_RESPONSE,
    payload: {
      error,
      twofa,
    },
  };
};

export const fetch2faThunk = (): SystemThunk => async dispatch => {
  try {
    dispatch(fetch2faRequest());
    const response = await api.account.get2FA();
    dispatch(fetch2faResponse(null, response));
  } catch (err) {
    if (err.response?.status === 404) {
      dispatch(fetch2faResponse(null, null));
    } else {
      dispatch(fetch2faResponse(err));
    }
  }
};

export const submit2faThunk = (payload: { service: string; type: string; code: string; secret: string }): SystemThunk => async dispatch => {
  try {
    dispatch(submit2faRequest());
    const response = await api.auth.submit2fa(payload.secret, payload.code, payload.type, payload.service);
    dispatch(submit2faResponse(null, response.user));
  } catch (err) {
    dispatch(submit2faResponse(err));
  }
};

const submit2faRequest = (): SystemActionTypes => {
  return {
    type: SUBMIT_2FA_REQUEST,
  };
};

const submit2faResponse = (error, user?: User): SystemActionTypes => {
  return {
    type: SUBMIT_2FA_RESPONSE,
    payload: {
      error,
      user,
      redirect2FA: false,
    },
  };
};

export const logoutThunk = (): SystemThunk => async dispatch => {
  await api.auth.logout();
  dispatch(logoutResponse());
};

const logoutResponse = (): SystemActionTypes => {
  return {
    type: LOGOUT_RESPONSE,
  };
};

export const editAccountThunk = (payload: {
  id: id;
  firstname: string;
  lastname: string;
  email: string;
  locale: string;
}): SystemThunk => async dispatch => {
  dispatch(editAccountRequest());
  const response = await api.account.editUser(payload.id, payload.firstname, payload.lastname, payload.email, payload.locale);
  dispatch(editAccountResponse(null, response));
};

const editAccountRequest = (): SystemActionTypes => {
  return {
    type: EDIT_ACCOUNT_REQUEST,
  };
};

const editAccountResponse = (error, user?: User): SystemActionTypes => {
  return {
    type: EDIT_ACCOUNT_RESPONSE,
    payload: {
      error,
      user,
    },
  };
};
