import { cloneDeep, uniq } from 'lodash';

import {
  CENTER_FETCH_FULFILLED,
  CENTER_PATCH_FULFILLED,
  CENTERS_LIST_FETCH_FULFILLED,
  CentersActionTypes,
  CentersState,
} from './centers.types';

const initialState = {
  byId: {},
  allIds: [],
};

export function centersReducer(state: CentersState = initialState, action: CentersActionTypes) {
  switch (action.type) {
    case CENTER_FETCH_FULFILLED: {
      if (action.payload?.address?._id) {
        delete action.payload.address._id;
      }
      return {
        byId: {
          ...state.byId,
          [action.payload._id]: cloneDeep(action.payload),
        },
        allIds: uniq([...state.allIds, action.payload._id]),
      };
    }
    case CENTERS_LIST_FETCH_FULFILLED: {
      const newCenters = action.payload.reduce((acc, value) => {
        if (value?.address?._id) {
          delete value.address._id;
        }
        acc[value._id] = cloneDeep(value);
        return acc;
      }, {});
      return {
        byId: {
          ...state.byId,
          ...newCenters,
        },
        allIds: uniq([...state.allIds, ...Object.keys(newCenters)]),
      };
    }
    case CENTER_PATCH_FULFILLED: {
      return {
        byId: {
          ...state.byId,
          [action.payload._id]: {
            ...state.byId[action.payload._id],
            ...action.payload,
          },
        },
        allIds: state.allIds,
      };
    }
    default:
      return state;
  }
}
